import React, { useState } from "react";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
  TableRow, Table,
  TableHead,
  TableBody,
  TableCell,
  Checkbox, FormControlLabel,
} from "@material-ui/core";
import AddClassService from "./Locality/Service/addClassService";
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import { Card, Box } from "@material-ui/core";
import StudentService from "./Locality/Service/studentService"
import EventService from "./Locality/Service/eventService";
import * as Yup from 'yup';
import TablePagination from '@material-ui/core/TablePagination';
import { Grid, Select, TextField } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { useParams } from "react-router-dom";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#30875b",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));
const useImageStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    imageList: {
      width: 500,
      height: 450,
    },
  }));
export default function ActivityDetails() {
    const { id } = useParams();
  const tableHeaders = [ 'Event Name ',  'classId', 'Date', 'Discription', 'Edit', 'Delete'];
  const [images, setImages] = useState('');
  const [imageslist, setImagesList] = useState('');
  const [message, setMessage] = useState("");
  const classes = useStyles();
  const imageclasses = useImageStyles();

  const [studentList, setStudentList] = useState([]);
  const [addClassList, setAddClassList] = useState([]);
  const [eventList, setEventList] = useState({imageURL:[]});
  const [startDate, setFormStartDate] = useState("");
  const [age, setAge] = React.useState('');
  var [error, setError] = useState(null);
  var [uploadError, setUploadError] = useState({ isShowError: false, successCount: 0, error: [], message: "" });
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [pg, setpg] = React.useState(0);
  const [rpg, setrpg] = React.useState(5);
  const current = new Date();
  var [classId, setClassId] = useState("");
  const date = `${current.getFullYear()}-0${current.getMonth() + 1}-${current.getDate()}`;
  var [dateValue, setDateValue] = useState(date);
  const [eventId, setEventId] = useState('');

  const [event, setEvent] = useState({
    eventName: '',
    imageURL: '',
    discription: '',
    video: '',
    classId: '',
    date: '',
    isEventForAllStudent: false,
    startDate: ''
  });

 
  useEffect(() => {
    getEventList();

   // getAddClassList();
    return () => {
      setEventList([]);
      setStudentList([]);
    }
  }, []);

 
  const getEventList = () => {
    EventService.calenderassignmentFind(id).then((res) => {
      setEventList(res);
    }).catch((err) => {
      setError(err.message);
    });
  }


 
  




 
  const itemData =[{
    img: 'https://artistimages.blob.core.windows.net/montestory/1733932314843WhatsApp Image 2024-11-28 at 10.45.16 AM.jpeg',
    title: 'Breakfast',
    author: 'jill111',
   
    featured: true,
  },{
    img: 'https://artistimages.blob.core.windows.net/montestory/1733932314843WhatsApp Image 2024-11-28 at 10.45.16 AM.jpeg',
    title: 'Breakfast',
    author: 'jill111',
   
    featured: true,
  },{
    img: 'https://artistimages.blob.core.windows.net/montestory/1733932314843WhatsApp Image 2024-11-28 at 10.45.16 AM.jpeg',
    title: 'Breakfast',
    author: 'jill111',
  
    featured: true,
  },{
    img: 'https://artistimages.blob.core.windows.net/montestory/1733932314843WhatsApp Image 2024-11-28 at 10.45.16 AM.jpeg',
    title: 'Breakfast',
    author: 'jill111',
  
    featured: true,
  },{
    img: 'https://artistimages.blob.core.windows.net/montestory/1733932314843WhatsApp Image 2024-11-28 at 10.45.16 AM.jpeg',
    title: 'Breakfast',
    author: 'jill111',
  
    featured: true,
  }]
  return (

    <>
      <PageTitle title={eventList.eventName}   />

      <Grid container spacing={4} >
        <Grid item xs={12}>
          <Widget title= "" upperTitle noBodyPadding bodyClass={classes.tableOverflow}
          style={{overflow:"hidden"}}>
       
            <section>
          <ImageList rowHeight={500} className={classes.imageList} cols={4}>
        {eventList?.imageURL?.map((item,index) => (
          <ImageListItem key={index} cols={ 1}>
            
            <img src={item} alt="Image"  />
          </ImageListItem>
        ))}
      </ImageList>
      <h5>{eventList?.discription}</h5>
      </section>
      <section>
      <iframe 
  width="560" 
  height="315" 
  src={eventList?.video} 
  title="YouTube video player" 
  frameborder="0" 
  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
  allowfullscreen>
</iframe>
      </section>
      </Widget>
      

     
       




      
          
        </Grid>
      </Grid>
     
    </>
  );
}


