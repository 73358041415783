import React, { useState } from "react";
import TablePagination from '@material-ui/core/TablePagination';
import { Button, TableRow, Table, TableHead, TableBody, Typography, TableCell } from "@material-ui/core";
import EventService from "./Locality/Service/eventService";
import { Grid, } from "@material-ui/core";
import { useFormik } from 'formik';
import { useEffect } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import Widget from "../../components/Widget/Widget";
import PageTitle from "../../components/PageTitle/PageTitle";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import StudentService from "./Locality/Service/studentService";
const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: "#30875b",
        color: theme.palette.common.white,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell);
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
}));
export default function Assignment(props, history) {
    const classes = useStyles();
    const [teacherList, setTeacherList] = useState([]);
    const [classNameList, setClassNameList] = useState([]);
    const [eventList, setEventList] = useState([]);
    const currentDate = new Date();
    const [age, setAge] = React.useState('');
    var [error, setError] = useState(null);
    const [teacherIdList, setTeacherIdList] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [pg, setpg] = React.useState(0);
    const [rpg, setrpg] = React.useState(1000);
    
    const [addUserPermitionsList, setUserPermitionsList] = useState({ studentDetailsEdit: false, studentDetailsDelete: false });
    const [event, setEvent] = useState({
        eventName: '',

        discription: '',

        date: '',
        startDate: '',
        dueDate: '',
    }); const handleChangePage = (event, newpage) => {
        setpg(newpage);
    }

    const handleChangeRowsPerPage = (event) => {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    useEffect(() => {
        getEventList();
        return () => {

            setEventList([]);

        }
    }, []);
    // const getUserPermitionsList=()=>{
    //     const userPermitions= ActivityService.userPermitions();
    //          setUserPermitionsList(userPermitions);
    //     }
    const onclick = () => {
        setOpen(true);
    }
    const resubmit = () => {
        
    };
    const handleClose = () => {
        setOpen(false);
      };
    const onSubmit = (values, status) => {
       values.resubmit=status;
        EventService.upadeEvent(values).then((res) => {
            handleClose();
            getEventList();
           
            alert("Submited Successfully.");
          }).catch((err) => {
          });
    };
    const handleChange = (event) => {
        setAge(event.target.value);
    };
    
    const dateAndTime = (date) => {

        const systemDate = new Date(date);
        const formattedDateTime = systemDate.toLocaleString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            // hour: 'numeric',
            // minute: 'numeric',
            // second: 'numeric',
            // hour12: true,

        });
        return formattedDateTime;
    }
    // const getEventList = () => {
    //     const userDetails = JSON.parse(localStorage.getItem("userDetail"));
    //     EventService.getAllEvent(userDetails.schooleId).then((res) => {
    //         setEventList(res);
    //     }).catch((err) => {
    //         // setError(err.message);
    //     });
    // }
    const getEventList = () => {
    
        EventService.getAllEvent()
          .then((res) => {
            
            const formattedEvents = res.map((event) => {
              const formattedDate = event.date ? formatDate(event.date):"";
              return { ...event, date: formattedDate };
            });
            
            setEventList(formattedEvents);
          })
          .catch((err) => {
            setError(err.message);
          });
      };
    // const getEventList = () => {
    //     EventService.getAllEvent()
    //         .then((res) => {
    //             const formattedEvents = res.map((event) => {
    //                 const formattedDate = formatDate(event.date);
    //                 return { ...event, date: formattedDate };
    //             });
    //             setEventList(formattedEvents);
    //         })
    //         .catch((err) => {
    //             setError(err.message);
    //         });
    // };
    const formatDate = (date) => {
        const [year, month, day] = date.split("-"); // Split the date string (YY-MM-DD)
        return `${day}-${month}-${year.slice(2)}`; // Return in DD-MM-YY format
    };

    const formik = useFormik({
        initialValues: event,
        enableReinitialize: true,
        onSubmit: (values, { resetForm }) => {

        },
    });
    return (
        <>

            <PageTitle title="Assignment" />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Widget title="" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>

                        <Table className="mb-0">
                            <TableHead >
                                <TableRow>
                                    <StyledTableCell >Assignment</StyledTableCell>
                                    <StyledTableCell > Assignment Date </StyledTableCell>
                                    <StyledTableCell >Due date for submission</StyledTableCell>

                                    {/* <StyledTableCell >Date of submission</StyledTableCell> */}
                                    <StyledTableCell >Remarks</StyledTableCell>
                                    <StyledTableCell >Status</StyledTableCell>

                                    <StyledTableCell >Request for resubmission</StyledTableCell>

                                </TableRow>
                            </TableHead>

                            <TableBody>



                                {eventList.slice(pg * rpg, pg * rpg + rpg).map((event) => {
                                    const eventDueDate = new Date(event.dueDate);
                                    const isPastDue = currentDate > eventDueDate;
                                  return(  <TableRow key={event._id}>

                                        <TableCell className="pl-3 fw-normal" >{event.eventName}</TableCell>
                                        {/* <TableCell className="pl-3 fw-normal" >{student.imageURL ? student.imageURL:'' }</TableCell> */}
                                        <TableCell className="pl-3 fw-normal" >{dateAndTime(event.createdAt)}</TableCell>

                                        {/* <TableCell className="pl-3 fw-normal" >{event.date}</TableCell> */}

                                        {/* <TableCell className="pl-3 fw-normal" >{event.startDate}</TableCell> */}
                                        <TableCell className="pl-3 fw-normal" >{event.dueDate}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{event.discription}</TableCell>
                                        <TableCell className="pl-3 fw-normal" >{event.status}</TableCell>

                                        <TableCell className="pl-3 fw-normal" >
                    
                                            {(isPastDue && event.resubmit==='Not Submited')? (
                                                <Button variant="contained" 
                                                // color="primary" 
                                                style={{backgroundColor:"#30875b"}}
                                                
                                                type="resubmit" onClick={() => onSubmit(event, "Resubmit")}>
                                                    Resubmit
                                                </Button>
                                            ) : (
                                                <></>
                                            )}      
                                            
                                                                              </TableCell>

                                    </TableRow>)
                                })}
                            </TableBody>
                        </Table>
                        <TablePagination
                            component="div"
                            rowsPerPageOptions={[1000, 50000, 100000]}
                            count={eventList.length}
                            page={pg}
                            onPageChange={handleChangePage}
                            rowsPerPage={rpg}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Widget>
                </Grid>
            </Grid>

        </>
    );
}


