import requests from './httpService';
const CalenderAssignmentService = {
  getAllCalenderAssignment() {
    return requests.get(`/calenderassignment/list`);
  },
  getCalenderAssignmentbyId(id) {
    return requests.get(`/calenderassignment/${id}`);
  },
  
  uploadImage(body) {
    return requests.post(`/calenderassignment/uploadimage`,body);
  },
  creteImages(body){
    return requests.post('/image/add',body);
  },
  creteCalenderAssignment(body){
    return requests.post('/calenderassignment/add',body); 
  },
  deleteCalenderAssignment(body){
    return requests.delete(`/calenderassignment/${body._id}`); 
  },
  findCalenderAssignmentList(body){
    return requests.post(`/calenderassignment/find`,body); 
  },

  upadeCalenderAssignment(body) {
    return requests.put(`/calenderassignment/${body._id}`,body); 
  },

};
export default CalenderAssignmentService;
